import { useAnimation } from "framer-motion";

export default function useAnimatedStickyHeader() {
  const animationStickyHeader = useAnimation();

  const animationShow = () => {
    animationStickyHeader.start({ opacity: 1, display: "block" });
  };

  const animationHide = () => {
    animationStickyHeader.start({ opacity: 0, display: "none" });
  };

  return {animationStickyHeader, animationShow, animationHide }
}
